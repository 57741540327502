import asyncComponent from "../components/common/AsyncComponent";

const AsyncMain = asyncComponent(() => import("./Main/MainView"));

export default [
  {
    canAccess: false,
    component: AsyncMain,
    exact: true,
    path: "/",
  },
];
