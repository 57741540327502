import { AnyAction } from "redux";

// ------------------------------------
// Constants
// ------------------------------------
export const SET_POPUP = "SET_POPUP";

export type Popup = {
  content: string | React.ReactNode;
  buttons?: {
    text: string;
    callback?: Function;
  }[];
};

// ------------------------------------
// Interface
// ------------------------------------
export interface IPopupState {
  popup: Popup | undefined;
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState: IPopupState = {
  popup: undefined,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action: AnyAction): IPopupState => {
  switch (action.type) {
    case SET_POPUP:
      return {
        ...state,
        popup: action.payload,
      };
    default:
      return state;
  }
};
