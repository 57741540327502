import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useState } from "react";

type ITab = {
  id: number;
  title: string;
  description: string;
};

interface IApAccordion {
  tabs: ITab[];
  customExpandIcon?: string;
  customCollapseIcon?: string;
  className?: string;
}

export default function ApAccordion(props: IApAccordion) {
  const { tabs = [], customExpandIcon, customCollapseIcon, className } = props;
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <Accordion
      className={className || ""}
      activeIndex={activeIndex}
      {...(customExpandIcon && { expandIcon: customExpandIcon })}
      {...(customCollapseIcon && { collapseIcon: customCollapseIcon })}
      onTabChange={(e: any) => setActiveIndex(e.index)}
    >
      {tabs.map((tab: any) => (
        <AccordionTab key={tab.id} header={tab.title}>
          <div className="wrp-description">{tab.description}</div>
        </AccordionTab>
      ))}
    </Accordion>
  );
}
