import classNames from "classnames";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import popupActions from "~/store/actions/popup";
import { IState } from "~/store/reducers/index";
import "./Popup.scss";

export default function Popup() {
  const dispatch = useDispatch();
  const popup = useSelector((state: IState) => state.popup.popup);

  const buttons = (popup && popup.buttons) ?? [
    {
      text: "Got It",
    },
  ];

  useEffect(() => {
    const closePopup = ({ key }: { key: string }) => {
      if (key === "Escape") {
        document.removeEventListener("keyup", closePopup, false);
        dispatch(popupActions.setPopup());
      }
    };

    if (popup) {
      document.addEventListener("keyup", closePopup, false);
    } else {
      document.removeEventListener("keyup", closePopup, false);
    }
  }, [popup]);

  return popup ? (
    <div className="popup-container">
      <div className="container">
        <div className="popup">
          <div className="content">{popup.content}</div>
          <footer>
            {buttons.length > 0 && (
              <div className="buttons">
                {buttons.map(({ text, callback }, key: number) => (
                  <button
                    key={key}
                    onClick={() => {
                      dispatch(popupActions.setPopup());
                      callback && callback();
                    }}
                    className={classNames({
                      two: popup.buttons ? popup.buttons.length > 1 : false,
                    })}
                  >
                    {text}
                  </button>
                ))}
              </div>
            )}
          </footer>
        </div>
      </div>
    </div>
  ) : null;
}
