import cn from "classnames";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { IState } from "~/store/reducers/index";
import { CONTRACT_TYPES } from "../constants";
import { checkBriefForPermanent } from "../helper";
import "./BriefInfo.scss";

export default function BriefInfo() {
  const [infoExpanded, setInfoExpanded] = useState(false);
  const brief = useSelector((state: IState) => state.brief);

  const isPermanentHiringBrief = useMemo(
    () => checkBriefForPermanent(brief),
    [brief.contract_type]
  );

  function expandInfo() {
    setInfoExpanded(!infoExpanded);
  }

  return (
    <div className="BriefInfo">
      <h1>
        Hi {brief.client_name}! <br /> Your results are in!
      </h1>
      <p>
        Please note this site is <b>live</b> and will continuously be updated.
      </p>
      <div className="BriefInfo__stats">
        <div>
          <h2>{brief.positive_responses}</h2>
          <p>
            Positive
            <br />
            Responses
          </p>
        </div>
        <div>
          <h2>{brief.approaches_made}</h2>
          <p>
            Approaches
            <br />
            Made
          </p>
        </div>
      </div>
      <p className="BriefInfo__first">
        <span>First Response</span>
        <strong>{brief.time_first_response || "No responses yet"}</strong>
      </p>
      <div className={cn("BriefInfo__info", { expanded: infoExpanded })}>
        <div>
          <strong>Brief Type</strong>
          {!!brief.contract_type && (
            <span>{CONTRACT_TYPES[brief.contract_type]}</span>
          )}
        </div>
        {brief?.job_title && isPermanentHiringBrief && (
          <div>
            <strong>Job Title</strong>
            <span>{brief.job_title}</span>
          </div>
        )}
        {brief?.role_involves && isPermanentHiringBrief && (
          <div>
            <strong>What the Role Involves</strong>
            <span className="multiline">{brief?.role_involves}</span>
          </div>
        )}
        {brief?.work_description && isPermanentHiringBrief && (
          <div>
            <strong>Key People</strong>
            <span className="multiline">{brief?.work_description}</span>
          </div>
        )}
        <div>
          <strong>Date brief set</strong>
          <span>{brief.date}</span>
        </div>
        <div>
          <strong>Company</strong>
          <span>{brief.company}</span>
        </div>
        {!!brief.requirement.trim() && (
          <div>
            <strong>Level & Specialism</strong>
            <span>{brief.requirement}</span>
          </div>
        )}
        {!isPermanentHiringBrief && (
          <>
            <div>
              <strong>Start</strong>
              <span>{brief.start}</span>
            </div>
            <div>
              <strong>End</strong>
              <span>{brief.end}</span>
            </div>
          </>
        )}
        <div>
          <strong>
            {isPermanentHiringBrief ? "The Opportunity" : "The Sell"}{" "}
          </strong>
          <span className="multiline">{brief.the_sell}</span>
        </div>
        {!!brief?.tags?.length && (
          <div>
            <strong>Superpowers</strong>
            <span className="multiline">{brief.tags?.join(", ")}</span>
          </div>
        )}
        <button type="button" onClick={expandInfo}>
          {infoExpanded ? "Hide" : "View"} Brief Info{" "}
          <i className={cn("icon-arrow-right", { iconHide: infoExpanded })}></i>
        </button>
      </div>
    </div>
  );
}
