import React from "react";
import { ITalents } from "~/store/constants/brief";
import Talent from "./Talent";
import "./Talents.scss";

const Talents = ({
  talents,
  last_update,
  mode = "normal",
}: {
  talents: ITalents[];
  last_update: string | false;
  mode?: "normal" | "spotlight";
}) => {
  return (
    <div className="talents-container">
      {!!last_update && (
        <div className="date-update">
          Updated with new talent: <span>{last_update}</span>
        </div>
      )}
      <div className="wrp-talents">
        {talents?.map((talent: ITalents, key: number) => (
          <Talent talent={talent} key={key} mode={mode} />
        ))}
      </div>
    </div>
  );
};

export default Talents;
