import React from "react";
import { useSelector } from "react-redux";
import Talents from "../Talents/Talents";
import LogoIcon from "~/assets/genie-logo.svg";
import { IState } from "~/store/reducers/index";
import "./TalentSection.scss";
import cn from "classnames";

const TalentSection = () => {
  const { talents, message, last_update, spotlight_talents } = useSelector(
    (state: IState) => state.brief
  );
  const canShowSpotlightSection =
    !!spotlight_talents?.length && !!talents?.length;
  const isNoTalentAccepted = !talents?.length;
  let briefMessage;

  if (message || isNoTalentAccepted) {
    briefMessage =
      message ||
      "Talent who are interested and available for your brief will appear here when they have responded positively";
  }

  return (
    <div
      className={cn("wrp-talent-section", {
        ["frame"]: canShowSpotlightSection,
      })}
    >
      {!!talents?.length && (
        <Talents talents={talents} last_update={last_update} />
      )}

      {briefMessage && (
        <div className="talents-container">
          <h1
            className={cn({
              ["interested-talents"]: isNoTalentAccepted && !message,
            })}
          >
            {briefMessage}
          </h1>
        </div>
      )}

      {!message && (
        <div className="wrp-candidates-rates">
          <div className="candidates-rates">
            <p className="logo">
              <img alt="Genie" src={LogoIcon} />
            </p>
            <p className="description">
              *Talent provide their preferred standard rate on a brief by brief
              basis. Where a day rate is provided this will apply only to
              standard working days. Talent may increase their day rate for
              weekend and public holiday work. Please discuss this directly with
              booked talent before the work gets underway.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default TalentSection;
