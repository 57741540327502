import React from "react";
import { Card } from "primereact/card";
import ApAccordion from "../common/ApAccordion";
import cn from "classnames";
import { ITalents } from "~/store/constants/brief";
import "./Talents.scss";

const CREATIVE_TEAM = "Creative Team";
const NO_REASON_GIVEN = "No Reason Given";

const Talent = ({
  talent,
  mode,
}: {
  talent: ITalents;
  mode: "normal" | "spotlight";
}) => {
  const header = (
    <a href={talent.portfolio_url} target="_blank" className="wrp-img">
      <img alt="Card" src={talent.image_url} />
    </a>
  );

  const feedbackTitle =
    talent.specialism === CREATIVE_TEAM ? "why we're right" : "why i'm right";

  const tabs = [
    {
      id: 0,
      title: "More info",
      description: (
        <>
          {talent?.notice_period && (
            <p className="notice-period">
              <b>Notice period:</b> {talent.notice_period}
            </p>
          )}
          {talent.country && (
            <p className="country">
              <b>Talent country:</b> {talent.country}
            </p>
          )}
          <p>{talent.description}</p>
        </>
      ),
    },
    ...(talent.talent_feedback && talent.talent_feedback !== NO_REASON_GIVEN
      ? [
          {
            id: 1,
            title: feedbackTitle,
            description: <p>{talent.talent_feedback}</p>,
          },
        ]
      : []),
    ...(talent.cv_url
      ? [
          {
            id: 2,
            title: "Portfolio / CV",
            description: (
              <>
                <a href={talent.portfolio_url} target="_blank">
                  <span className="pi pi-external-link" /> View Portfolio
                </a>
                <a href={talent.cv_url} target="_blank">
                  <span className="pi pi-download" /> Download CV
                </a>
              </>
            ),
          },
        ]
      : []),
  ];

  const title = (
    <>
      <p>{talent.name}</p>
      <p>{talent.subhead}</p>
    </>
  );

  const subTitle = (
    <>
      <p>{talent.specialism}</p>
      {talent?.masked_talent_rate ? (
        <p>Salary range: {talent.masked_talent_rate}</p>
      ) : (
        <p>
          {mode === "normal" && (
            <>
              {talent.cost_title}: {talent.cost_string}
            </>
          )}
          {mode === "spotlight" && <>Day rate: £{talent.rate}</>}
        </p>
      )}
    </>
  );

  return (
    <Card
      title={title}
      subTitle={subTitle}
      header={header}
      className={cn(
        (!talent.talent_feedback ||
          talent.talent_feedback === NO_REASON_GIVEN) &&
          "without-one-tab"
      )}
    >
      <div className="p-m-0 card card-content">
        <ApAccordion
          tabs={tabs}
          className="accordion-custom"
          customExpandIcon="pi pi-chevron-down"
          customCollapseIcon="pi pi-chevron-up"
        />
        {!talent.cv_url && (
          <div className="accordionItem">
            <a href={talent.portfolio_url} target="_blank">
              Portfolio
            </a>
          </div>
        )}
        {mode === "spotlight" && (
          <div className="addTalent">
            <a
              className="p-button addTalentButton"
              href={`mailto:support@meetgenie.co?subject=I would like to add ${talent.name}`}
            >
              Get in Touch
              <span className="pi pi-external-link talent-details-link"></span>
            </a>
          </div>
        )}
      </div>
    </Card>
  );
};

export default Talent;
