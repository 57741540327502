export const CONTRACT_TYPES: iContractTypes = {
  FIXEDTERM: "Fixed term contract",
  FREELANCE: "Day rate gig",
  PERMANENT: "Permanent Hiring",
};
export interface iContractTypes {
  FIXEDTERM: string;
  FREELANCE: string;
  PERMANENT: string;
}
