import "primeflex/primeflex.min.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/nova/theme.css";
import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import Popup from "~/components/Popup/Popup";
import routes from "~/routes";
import "./App.scss";
import "./theme-override.scss";

const App = (props: RouteComponentProps) => {
  useEffect(() => {
    const searchParams = Object.fromEntries(
      new URLSearchParams(props.location.search) as any
    );

    if (searchParams.debug !== "true") {
      window.console.log = () => {};
      window.console.group = () => {};
      window.console.groupEnd = () => {};
      window.console.groupCollapsed = () => {};
      window.console.warn = () => {};
      window.console.error = () => {};
    }
  }, []);

  return (
    <main className="App" tabIndex={0}>
      <Switch location={props.location}>
        {routes.map((route: any, i: number) => (
          <Route key={i} {...route} />
        ))}
      </Switch>
      <Popup />
    </main>
  );
};

export default withRouter(App);
