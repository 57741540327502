import { Popup, SET_POPUP } from "../reducers/popup";

// ------------------------------------
// Actions
// ------------------------------------
export default {
  setPopup: (popup?: Popup) => ({
    type: SET_POPUP,
    payload: popup,
  }),
};
