import React from "react";
import "./FooterSection.scss";

const FooterSection = () => (
  <footer className="footer-section">
    <ul className="top-links">
      <li>
        <a className="link" href="https://meetgenie.co/privacy-policy/">
          Privacy Policy
        </a>
      </li>
      <li>
        <a className="link" href="https://meetgenie.co/cookie-policy/">
          Cookie Policy
        </a>
      </li>
      <li>
        <a className="link" href="https://meetgenie.co/equality-policy">
          Equality Policy
        </a>
      </li>
    </ul>
    <ul className="tell-me-more">
      <li>
        <span>
          Not the hiring manager that created this brief? Refer back to them for
          everything talent related. For anything else it’s&nbsp;
          <a className="link" href="mailto:support@meetgenie.co">
            support@meetgenie.co
          </a>
        </span>
      </li>
    </ul>
  </footer>
);

export default FooterSection;
