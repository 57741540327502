import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import "./Loader.scss";

export default function Loader({
  className = "",
  size = "2",
}: {
  className?: string;
  size?: string;
}) {
  return (
    <div className="bg-loader">
      <ProgressSpinner
        className={className}
        strokeWidth={size}
        animationDuration="1s"
      />
    </div>
  );
}
