import { AnyAction } from "redux";
import * as Brief from "../constants/brief";

// ------------------------------------
// Interface
// ------------------------------------
export interface IBriefState {
  isFetching: boolean;
  approaches_made: number | null;
  client_name: string;
  job_title: string;
  company: string;
  date: string;
  end: string;
  id: number | null;
  last_update: string;
  message: string;
  requirement: string;
  positive_responses: number | null;
  start: string;
  talents?: Brief.ITalents[];
  spotlight_talents?: Brief.ITalents[];
  the_sell: string;
  time_first_response: string;
  tags?: string[];
  contract_type: string;
  role_involves?: string;
  work_description?: string;
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState: IBriefState = {
  isFetching: false,
  approaches_made: null,
  client_name: "",
  job_title: "",
  company: "",
  date: "",
  end: "",
  id: null,
  last_update: "",
  message: "",
  requirement: "",
  positive_responses: null,
  start: "",
  talents: [],
  the_sell: "",
  time_first_response: "",
  tags: [],
  contract_type: "",
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action: AnyAction): IBriefState => {
  switch (action.type) {
    case Brief.GET_BRIEF:
      return {
        ...state,
        isFetching: true,
      };
    case Brief.GET_BRIEF_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      };
    case Brief.GET_BRIEF_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case Brief.RESET_BRIEF:
      return initialState;
    default:
      return state;
  }
};
