import location, { ILocationState } from "./location";
import brief, { IBriefState } from "./brief";
import popup, { IPopupState } from "./popup";

export interface IState {
  location: ILocationState;
  brief: IBriefState;
  popup: IPopupState;
}

export default {
  location,
  brief,
  popup,
};
