import React from "react";
import "./SidePanel.scss";

export default function SidePanel() {
  return (
    <aside className="SidePanel">
      <a href="https://meetgenie.co">
        <i className="SidePanel__Logo"></i>
      </a>
    </aside>
  );
}
