import React from "react";
import { useSelector } from "react-redux";
import { IState } from "~/store/reducers/index";
import Talents from "../Talents/Talents";
import "./SpotlightSection.scss";

const SpotlightSection = () => {
  const { talents, spotlight_talents } = useSelector(
    (state: IState) => state.brief
  );

  return (
    <>
      {!!spotlight_talents?.length && !!talents?.length && (
        <div className="wrp-talent-section spotlight frame">
          <h1 className="spotlight">Wildcard Talent</h1>
          <p>
            Here’s some great talent that is not a match for your brief right
            now, but might inspire something else.
            <br />
            Right now we’re focusing on Ukrainian talent with 100% of booking
            fees going to support humanitarian aid.
          </p>
          <Talents
            talents={spotlight_talents}
            last_update={false}
            mode="spotlight"
          />
        </div>
      )}
    </>
  );
};

export default SpotlightSection;
