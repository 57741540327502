import { createBrowserHistory, History } from "history";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router";
import { Store } from "redux";
import App from "./components/App/App";
import createStore from "./store";

// ------------------------------------
// Store Initialization
// ------------------------------------
const history = createBrowserHistory();
const store = createStore(window["__INITIAL_STATE__"], history);

const Application = ({
  store,
  history,
}: {
  store: Store;
  history: History;
}) => (
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
);

// ------------------------------------
// Entry point
// ------------------------------------
render(Application({ store, history }), document.getElementById("root"));
