import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import FooterSection from "~/components/FooterSection/FooterSection";
import TalentSection from "~/components/TalentSection/TalentSection";
import SpotlightSection from "~/components/SpotlightSection/SpotlightSection";
import BriefInfo from "~/components/BriefInfo/BriefInfo";
import SidePanel from "~/components/SidePanel/SidePanel";
import brief from "~/store/actions/brief";
import "./MainView.scss";
import Loader from "~/components/Loader/Loader";
import { IState } from "~/store/reducers/index";
import { getBriefId } from "~/utils";

const MainView = (props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const loading = useSelector((state: IState) => state.brief.isFetching);
  const briefId = getBriefId(props.location.hash);

  useEffect(() => {
    briefId &&
      fetchBriefInfo(() => dispatch(brief.getBriefById(briefId)), 5000);
  }, []);

  function fetchBriefInfo(fn: any, timeout: number) {
    fn();
    // setInterval(fn, timeout);
  }

  return (
    <div className="MainView">
      <SidePanel />
      <BriefInfo />
      <TalentSection />
      <SpotlightSection />
      <FooterSection />
      {loading && <Loader />}
    </div>
  );
};

export default withRouter(MainView);
