import { Dispatch } from "redux";
import { BriefService } from "~/API/BriefService";
import Brief from "../constants/brief";

// ------------------------------------
// Actions
// ------------------------------------

export default {
  getBriefById: (briefId: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(Brief.getBriefRequest());
      const res = await BriefService.getBrief(briefId as string);
      dispatch(Brief.getBriefSuccess(res));
    } catch (err) {
      dispatch(Brief.getBriefFailure());
    }
  },
};
