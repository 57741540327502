import { AxiosResponse } from "axios";

// ------------------------------------
// Constants
// ------------------------------------
export const GET_BRIEF = "GET_BRIEF";
export const GET_BRIEF_SUCCESS = "GET_BRIEF_SUCCESS";
export const GET_BRIEF_ERROR = "GET_BRIEF_ERROR";
export const RESET_BRIEF = "RESET_BRIEF";

// ------------------------------------
// Interfaces
// ------------------------------------

export type ITalents = {
  image_url: string;
  name: string;
  cost_title: string;
  cost_string: string;
  rate?: number;
  talent_feedback: string;
  description: string;
  portfolio_url: string;
  specialism: string;
  subhead: string;
  last_response_time: string;
  country: string;
  cv_url: string;
  notice_period: string;
  masked_talent_rate: string;
};

// ------------------------------------
// Action Creators
// ------------------------------------
export default {
  getBriefRequest: () => ({
    type: GET_BRIEF,
  }),
  getBriefSuccess: (res: AxiosResponse) => ({
    type: GET_BRIEF_SUCCESS,
    payload: res,
  }),
  getBriefFailure: () => ({
    type: GET_BRIEF_ERROR,
  }),
  resetBrief: () => ({
    type: RESET_BRIEF,
  }),
};
